import React from 'react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { Loader } from '@aws-amplify/ui-react'

export function LivenessDetector() {
	const params = new URLSearchParams(window.location.search)
	const w = window as any

	const [loading, setLoading] = React.useState<boolean>(false)
	const [sessionId, setSessionId] = React.useState<string | null>(params.get('session_id'))
	const [host, setHost] = React.useState<string | null>(params.get('host'))
	const [successUrl] = React.useState<string | null>(params.get('success_url'))
	const [errorUrl] = React.useState<string | null>(params.get('error_url'))
	const [cancelUrl] = React.useState<string | null>(params.get('cancel_url'))

	const onEvent = (event: string, payload: any) => {
		switch (event) {
			case "onHandleAnalysis":
				if (successUrl) {
					w.location.replace(successUrl)
					return
				}
				break;
			case "onError":
				if (errorUrl) {
					w.location.replace(errorUrl)
					return
				}
				break;
			case "onUserCancel":
				if (cancelUrl) {
					w.location.replace(cancelUrl)
					return
				}
				break;

			default:
				break;
		}

		if (w.flutter_inappwebview !== undefined && w.flutter_inappwebview !== null) {
			w.flutter_inappwebview.callHandler(event, payload)
		}
	}



	React.useEffect(() => {
		const fetchCreateLiveness: () => Promise<void> = async () => {
			if (!sessionId) {
				setLoading(true)
				if (!host) {
					setHost('https://engine.platnova.com')
				}
				const response = await fetch(`${host}/v1/accounts/selfie_capture/start`)
				const data = await response.json()

				setSessionId(data.data)
				setLoading(false)
			}
		}
		fetchCreateLiveness()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleAnalysisComplete: () => Promise<void> = async () => {
		onEvent('onHandleAnalysis', {})
	}

	const dictionary = {
		en: {
			waitingCameraPermissionText: 'Waiting for camera permission.',
		},
	}

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div>
					<FaceLivenessDetector
						sessionId={sessionId!}
						region='us-east-1'
						displayText={dictionary['en']}
						disableStartScreen
						onAnalysisComplete={handleAnalysisComplete}
						onUserCancel={() => {
							onEvent('onUserCancel', {})
						}}
						onError={(error: any) => {
							onEvent('onError', { error: error })
						}}
					/>
				</div>
			)}
		</>
	)
}
