import React from 'react'
import { Theme, ThemeProvider, ColorMode } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import config from './amplifyconfiguration.json'
import { LivenessDetector } from './views/LivenessDetector'
import './face-liveness.css'

Amplify.configure(config)

export default function App() {
	const params = new URLSearchParams(window.location.search)

	const [themeMode] = React.useState<ColorMode | null>(params.get('theme_mode') as ColorMode)

	const theme: Theme = {
		name: 'Face Liveness',
		overrides: [
			{
				colorMode: 'light',
				tokens: {
					components: {
						button: {
							link: {
								_focus: {
									backgroundColor: { value: '#7048f7' },
									boxShadow: { value: 'none' },
								},
							},
							primary: {
								backgroundColor: { value: '#7048f7' },
								_hover: {
									backgroundColor: { value: '#7048f7' },
								},
								_active: {
									backgroundColor: { value: '#7048f7' },
								},
								_focus: {
									backgroundColor: { value: '#7048f7' },
								},
							},
						},
					},
					colors: {
						primary: {
							80: { value: '#7048f7' },
						},
						font: {
							error: { value: '#EB5757' },
						},
						background: {
							primary: { value: '#fff' },
						},

						border: {
							primary: { value: 'transparent' },
						},
					},
				},
			},
			{
				colorMode: 'dark',
				tokens: {
					colors: {
						primary: {
							80: { value: '#7048f7' },
						},
						font: {
							error: { value: '#EB5757' },
							primary: { value: '{colors.white}' },
						},
						background: {
							primary: { value: '#0F0F0F' },
							tertiary: { value: '#0F0F0F' },
							quaternary: { value: '#0F0F0F' },
						},
					},
				},
			},
		],
	}

	return (
		<ThemeProvider theme={theme} colorMode={!themeMode && themeMode === '' ? 'light' : themeMode!}>
			<LivenessDetector />
		</ThemeProvider>
	)
}
